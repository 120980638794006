@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Archivo+Black');
@import url('https://fonts.googleapis.com/css?family=Oleo+Script:400,700&display=swap');

@font-face {
  font-family: 'VistaSansAltBlackIta';
  src: url('./assets/font/VistaSansAltBoldIta.eot?#iefix') format('embedded-opentype'), url('./assets/font/VistaSansAltBoldIta.woff') format('woff'), url('./assets/font/VistaSansAltBoldIta.ttf') format('truetype'), url('./assets/font/VistaSansAltBoldIta.svg#VistaSansAltBoldIta') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamMedium';
  src: url('./assets/font/GothamMedium.eot?#iefix') format('embedded-opentype'), url('./assets/font/GothamMedium.woff') format('woff'), url('./assets/font/GothamMedium.ttf') format('truetype'), url('./assets/font/GothamMedium.svg#GothamMedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBook';
  src: url('./assets/font/GothamBook.eot?#iefix') format('embedded-opentype'), url('./assets/font/GothamBook.woff') format('woff'), url('./assets/font/GothamBook.ttf') format('truetype'), url('./assets/font/GothamBook.svg#GothamBook') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('./assets/font/MyriadPro-Regular.eot');
  src: url('./assets/font/MyriadPro-Regular.woff') format("woff"), url('./assets/font/MyriadPro-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/font/HelveticaNeue.eot');
  src: url('./assets/font/HelveticaNeue.woff') format("woff"), url('./assets/font/HelveticaNeue.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/font/Helvetica.eot');
  src: url('./assets/font/Helvetica.woff') format("woff"), url('./assets/font/Helvetica.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('./assets/font/fontawesome-webfont.eot');
  src: url('./assets/font/fontawesome-webfont.woff') format("woff"), url('./assets/font/fontawesome-webfont.ttf') format("truetype"), url('./assets/font/fontawesome-webfont.svg#fontawesomeregular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'GothamBook', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
}

.parentToolTip {
  position: relative;
}

.parentToolTip #childTooltip {
  display: none;
}

.parentToolTip:hover #childTooltip {
  display: block;
  position: absolute;
}

.parentToolTip .childTooltip {
  display: none;
}

.parentToolTip:hover .childTooltip {
  display: block;
  position: absolute;
}

.background-play {
  background-image: linear-gradient(45deg, #A9C2DA, #3173AF, #073763B3, #A9C2DA, #6F9AC5);
  background-size: 500%;
  animation-name: bar;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes bar {
  0% {
    background-position: 0% 20%;
  }

  20% {
    background-position: 20% 40%;
  }

  40% {
    background-position: 40% 60%;
  }

  60% {
    background-position: 60% 80%;
  }

  80% {
    background-position: 80% 100%;
  }

  100% {
    background-position: 0% 20%;
  }
}