.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* Start Sending/Delivery Option */
.send-del-option {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.send-del-option .optn-container {
  width: 300px;
  min-height: 80px;
}

.send-del-option .optn-container label {
  display: block;
  position: relative;
  padding-top: 5px;
  padding-left: 40px;
  padding-bottom: 5px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  border: 1px solid #b4b4b4;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.send-del-option .optn-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.send-del-option .optn-container label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 18px;
  min-width: 18px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #b4b4b4;
  margin-top: 10px;
  margin-left: 10px;
}

.send-del-option .optn-container input[type=radio]:checked+label .checkmark {
  background-color: #fff;
  border: 2px solid #d00149;
}

.send-del-option .optn-container label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #d00149;
}

.send-del-option .optn-container input[type=radio]:checked+label .checkmark:after {
  display: block;
}

.send-del-option .optn-container input[type=radio]:checked+label {
  background: #f7ccd33d;
  border: 1px solid #c22f48;
}

/* .send-del-option .optn-container label .checkmark:after {
	top: 18%;
	left: 18%;
	width: 65%;
	height: 65%;
	border-radius: 50%;
	background: #d00149;
} */

.send-del-option .optn-container label .send-del-optn-body {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.send-del-option .optn-container label .send-del-optn-icon {
  width: 70px;
  height: 100%;
}

.send-del-option .optn-container label #rider-pickup-icon.send-del-optn-icon {
  background: url(./assets/images/pick-up.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.send-del-option .optn-container label #drop-off-icon.send-del-optn-icon {
  background: url(./assets/images/drop-off.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.send-del-option .optn-container label #rider-delivery-icon.send-del-optn-icon {
  background: url(./assets/images/rider-delivery.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.send-del-option .optn-container label #branch-pickup-icon.send-del-optn-icon {
  background: url(./assets/images/branch-pickup.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.send-del-option .optn-container label .send-del-optn-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 1.5em;
}

.send-del-option .optn-container label .send-del-optn-details .title {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

/* End Sending/Delivery Option */


/* Start react-select classes */
.react-select__control {
  border-radius: 9px !important;
}

.react-select-error .react-select__control {
  --tw-border-opacity: 1 !important;
  border-color: rgb(233 65 40 / var(--tw-border-opacity)) !important;
}

.react-select-error .react-select__control:focus-within {
  border-color: #2684FF !important;
}

.react-select__placeholder {
  text-align: left !important;
}

.react-select__single-value {
  text-align: left !important;
}

.react-select__input {
  border-color: #fff !important;
  box-shadow: 0 0 0 0 #fff !important;
}

.react-select__option {
  text-align: left !important;
  background-color: #fff !important;
  color: hsl(0, 0%, 20%) !important;
}

.react-select__option:hover {
  background-color: #deebff !important;
}

/* End react-select classes */

.pickup-calendar {
  margin: 0;
}

.amt-to-pay-container {
  width: 100%;
  max-width: 432px;
}

.amt-to-pay-container table {
  width: 100%;
  border-collapse: collapse;
}

.amt-to-pay-container table td {
  padding: 3px 0;
}

.amt-to-pay-container .amt-to-pay-label {
  text-align: left;
  color: #394146;
}

.amt-to-pay-container .amt-to-pay-value {
  text-align: right;
  font-weight: bold;
  color: #394146;
}

.amt-to-pay-container tbody tr:last-child td {
  padding-bottom: 10px;
}

.amt-to-pay-container tfoot {
  border-top: 1px solid #e8e7e7;
  color: #d31245;
  font-weight: bold;
}